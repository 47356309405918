.contactIcon {
  color: var(--color-white);
  font-size: 3rem;
}
.contactContainer {
  display: grid;
  grid-template-columns: 40% 45%;
  gap: 5%;
  padding: 5%;
}
.contact {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}
.contactContent p {
  font-size: 1.5vw;
}
.contactCards {
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 1.5rem;
}
.contactCard {
  background-color: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
}
.contactCard:hover {
  border: 1px solid var(--color-primary);
}
.contactImage {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(-5deg);
  transition: 0.5s;
}
.contactImage:hover {
  transform: rotate(0);
}
.imgBike {
  width: 100%;
  border-radius: 2rem;
}
.contact {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-bg-variant),
    transparent
  );
  display: grid;
  margin-top: auto;
  margin-bottom: auto;
}
/* medium devices */
@media screen and (max-width: 1024px) {
  .contactContainer {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .contactCards {
    grid-template-columns: 1fr;
    width: 80%;
    padding: 1rem;
    margin: 0 auto;
  }
  .experienceCard {
    padding: 1rem;
  }
  .contactCard {
    padding: 1rem;
  }
  .contactCard h4 {
    font-size: 3vw;
  }
  .contactCard small {
    font-size: 2vw;
  }
  .contact {
    width: 70%;
    margin: 2rem auto 4rem;
  }
}

/* small devices */
@media screen and (max-width: 600px) {
  .experienceCard h4 {
    font-size: 5vw;
  }
  .experienceCard small {
    font-size: 3vw;
  }
}
