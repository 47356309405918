* {
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
:root {
  --color-bg: #0d2137;
  --color-bg-variant: #061b32;
  --color-primary: #2e77ae;
  --color-primary-variant: #ff8e2b;
  --color-white: #ffff;
  --color-light: #e0eaf5;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "longhaul";
  src: url(assets/fonts/Longhaul.ttf);
}

body {
  background-color: var(--color-bg);
}

p {
  color: var(--color-white);
  text-align: left;
}
small {
  color: var(--color-white);
  font-size: 1.5vw;
}
h2 {
  color: var(--color-primary);
  font-size: 4vw;
}
h4 {
  color: var(--color-white);
  font-size: 2vw;
}
h5 {
  color: var(--color-white);
  font-size: 1.5vw;
}
